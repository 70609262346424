
import axios from 'axios';
export default {
    
        async createAccountTransfers(params)  {
            return await axios.post(`account_transfers/create` , params)
        },
        async createAccountTransfersList(params)  {
            return await axios.post(`account_transfers/create/list` , params)
        },
        async updateAccountTransfersColumn(column , value , data)  {
            return await axios.put(`account_transfers/update_list?${column}=${value}` , data)
        },
        async deleteAccountTransfersList(list)  {
            return await axios.delete(`account_transfers/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportAccountTransfers(columns)  {
            return await axios.get(`account_transfers/report?${columns}`)
        },
        async getAllAccountTransfers()  {
            return await axios.get(`account_transfers/all`)
        },
        async getOneAccountTransfers(account_transfer_id)  {
            return await axios.get(`account_transfers/all/${account_transfer_id}`)
        },
        async getAccountTransfersByColumn(column , value)  {
            return await axios.get(`account_transfers/filter?column=${column}&value=${value}`)
        },
        async deleteAccountTransfers(account_transfer_id)  {
            return await axios.delete(`account_transfers/delete/${account_transfer_id}`)
        },
        async updateAccountTransfers(account_transfer_id , params)  {
            return await axios.put(`account_transfers/update/${account_transfer_id}` , params)
        },
}
        